// CSS file fore WLW theme
// Created by Benedict Bartsch, 2019



// ------- FONTS ------- 
$font-stack: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;


// ------- COLORS ------- 

$teal: #248C8F;
$mint: #40CAA2;
$orange: #F6AE3A;
$red: #F0592A;
$grey: grey;

// ------- CSS ------- 
body {
    font: 100% $font-stack;
    font-size: 16px;
    color: white;
    background-color: black;
    padding: 1.5em;
    margin: auto;
  }

/*SVG ICON SYSTEM*/
.icon {
  display: inline-flex;
  align-self: center;
}

.icon svg, .icon img {
  height: 1em;
  width: 6.5em;
  fill: currentColor;
}

.icon.baseline svg, .icon img {
  top: .21em;
  position: relative;
}

.site-footer {
  p {
    font-size: 1em;
  }

}

p {
  font-size: 1.2em;
}

a {
  color: $grey;
  text-decoration: none;
}

.item {
  color: $teal;
}

.main a {
  color: $mint;
  text-decoration: none;
}

.main {
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
}
  
  @media only screen and (min-width: 321px) {

    .main {
      font-size: 1.5em;
    }
  }
  @media only screen and (min-width: 481px) {

    .main {
      font-size: 2.5em;
    }
  }
  @media only screen and (min-width: 769px) {

    .main {
      font-size: 3.5em;
    }
  }
  @media only screen and (min-width: 1025px) {

    .main {
      font-size: 4.5em;
    }
  }
  @media only screen and (min-width: 2000px) {

    .main {
      font-size: 5.5em;
    }
  }